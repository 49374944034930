/* src/ProjectDetails.css */

.project-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  color: #4c5176;
}
.project-body {
  display: flex;
}
.scenes-list {
  width: 30%;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
}

.scene-details {
  width: 45%;
  margin-left: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
  flex-basis: 55%;
  flex-grow: 1;
  min-width: 400px;
}
.project-title-update {
  flex-basis: 45%;
  margin-left: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
}
.scenes-list ul {
  padding-left: 32px;
}
ul {
  list-style: none;
  padding: 0;
}
li {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically centered */
  position: relative;
  margin-bottom: 16px;
  background-color: white;
}
li.selected::after {
  content: "";
  display: flex;
  position: absolute;
  left: -2px;
  top: 50%;
  width: 100%;
  height: 100%;
  border: 2px solid #ffc800;
  transform: translateY(-50%);
  border-radius: 4px;
}
li.selected .scene-order {
  height: calc(100% + 4px);
}
li:hover {
  background-color: #e6e6e6;
  cursor: pointer;
}

.scene-order {
  background-color: #ffc800;
  border-radius: 4px;
  padding: 5px;
  margin-left: 10px;
  position: absolute;
  top: 50%;
  left: -42px;
  transform: translateY(-50%);
  width: 32px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.scene-order.vertical {
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: 12px;
}

button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: grey;
  color: white;
  z-index: 999;
}

button.delete {
  background-color: rgb(239, 137, 137);
}
button.add {
  padding: 10px 20px;
  background-color: #28a745;
  min-width: max-content;
}

button.add:hover {
  background-color: #218838;
}
button.delete:hover {
  background-color: rgb(236, 117, 117);
}
input,
textarea {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  flex-basis: 100%;
}

.add-scene {
  display: flex;
}
.add-scene .add {
  width: 100px;
}
.update {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
.update input,
.update textarea {
  flex-basis: 50%;
  margin-right: 8px;
}

.take-list ul {
  padding-left: 0 !important;
}
.takes-list li {
  background-color: #ddd;
  border-radius: 4px;
}

.take-thumbnail,
.take-video {
  background-size: cover;
  width: 100px;
  aspect-ratio: 16/9;
  border-radius: 4px;
  margin-right: 8px;
}
.take-video {
  object-fit: contain;
}
.add-take {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}
h4 {
  margin-top: 32px;
  margin-bottom: 16px;
}
.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
}

.project-header h1 {
  margin: 0;
}

.update-project-title {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 50%;
}

.update-project-title input {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.update-project-title button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: max-content;
}

.update-project-title button:hover {
  background-color: #218838;
}

.upload-btn {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #28a745;
  color: white;
  z-index: 999;
  font-size: 14px;
}
.flags-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  margin-top: 20px;
  border-top: 1px solid #ddd;
}

.progress-wrapper {
  display: flex;
  flex-direction: column;
}
.progress-wrapper .title {
  color: #4c5176;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.progress-wrapper progress {
  width: 100%;
  margin-top: 13px;
}
.progress-wrapper progress::-webkit-progress-bar {
  border-radius: 20px;
  background-color: #eff1f5;
}
.progress-wrapper progress::-webkit-progress-value {
  border-radius: 20px;
  background-color: #28a745;
}
.progress-wrapper .file-lenght-wrapper {
  display: block;
  text-align: end;
  margin-top: 10px;
}
.progress-wrapper .file-lenght-wrapper .loaded,
.progress-wrapper .file-lenght-wrapper .total {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.16px;
  color: #4c5176;
}
.progress-wrapper .file-lenght-wrapper .loaded {
  margin-right: 6px;
}
.progress-wrapper .file-lenght-wrapper .loaded::after {
  content: "/";
  margin-left: 6px;
}
.progress-wrapper .file-upload-progress {
  display: block;
  text-align: end;
  margin-top: 12px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.16px;
  color: #4c5176;
}
.file-upload-progress {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 32px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 9999;
}
.progress-wrapper progress {
  width: 100%;
  height: 8px;
  margin-top: 13px;
}
.progress-wrapper progress::-webkit-progress-bar {
  border-radius: 10px;
  background-color: #eff1f5;
}
.progress-wrapper progress::-webkit-progress-value {
  border-radius: 5px;
  background-color: #28a745 !important;
}
.progress-wrapper progress::-moz-progress-bar {
  border-radius: 5px;
  background-color: #28a745; /* Firefox support */
}

.progress-wrapper progress::-ms-fill {
  border-radius: 10px;
  background-color: #28a745; /* Edge support */
}
/* Fallback for unsupported cases */
progress[value] {
  background-color: #eff1f5;
}

progress[value]::-webkit-progress-value {
  background-color: #28a745;
}
