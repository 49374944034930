/* Login.css */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
}

body,
html {
  height: 100%;
  /*  display: flex;
  justify-content: center;
  align-items: center; */
  background-color: #f0f2f5;
}

.login-container {
  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #4c5176;
}

.login-box {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 350px;
  color: #4c5176;
}

h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #4c5176;
}

.input-group {
  margin-bottom: 1.5rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4c5176;
}

.input-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.input-group input:focus {
  outline: none;
  border-color: #007bff;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 0;
}

.login-button:hover {
  background-color: #28a745;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 1rem;
}
