.checkbox-toggle {
  display: flex;
  align-items: center;
}
.checkbox-toggle.label-right {
  flex-flow: row-reverse;
  justify-content: left;
}
.checkbox-toggle.label-right .checkmark {
  margin-right: 12px;
}
.checkbox-toggle.label-right .checkbox-label {
  margin-right: 0;
}
.checkbox-toggle.between {
  justify-content: space-between;
}
.checkbox-toggle .checkbox-toggle-label {
  cursor: pointer;
  margin: 0;
  padding: 0;
}
.checkbox-toggle .checkbox-label {
  margin-right: 12px;
  color: #4c5176;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.checkbox-toggle .checkmark {
  display: flex;
  position: relative;
  width: 24px;
  height: 16px;
  border-radius: 100px;
  background-color: #e9eaee;
}
.checkbox-toggle .checkmark.toggle::after {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  background-color: white;
  fill: #fff;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.08))
    drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.04));
  transition: all 0.2s linear;
}
.checkbox-toggle .checkmark.classic {
  width: initial;
  height: initial;
  background-color: white;
}
.checkbox-toggle .checkmark.classic::after {
  content: "";
  width: 16px;
  height: 16px;
  position: relative;
  left: initial;
  top: initial;
  transform: none;
  border-radius: initial;
  fill: none;
  filter: none;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='14' height='14' rx='2' stroke='%23A5A8BB' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.checkbox-toggle input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.checkbox-toggle input:checked ~ .checkmark.toggle:after {
  left: 10px;
}
.checkbox-toggle input:checked ~ .checkmark.toggle {
  background-color: #28a745;
}
.checkbox-toggle input:checked ~ .checkmark.classic:after {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='3' fill='%2328a745'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.6508 4.24076C13.0701 4.60018 13.1187 5.23148 12.7592 5.65081L7.61832 11.6486C6.75581 12.6548 5.17086 12.5591 4.4357 11.4564L3.16793 9.55472C2.86158 9.09519 2.98576 8.47432 3.44528 8.16797C3.90481 7.86162 4.52568 7.98579 4.83203 8.44532L6.0998 10.347L11.2407 4.34923C11.6001 3.9299 12.2314 3.88134 12.6508 4.24076Z' fill='white'/%3E%3C/svg%3E%0A");
}
.checkbox-toggle.disabled .checkmark {
  opacity: 0.5;
}
.checkbox-toggle.disabled .checkbox-toggle-label {
  cursor: default;
}
