.projects-list {
  text-align: center;
  padding-bottom: 20px;
}

.projects-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 32px;
}

.project-item {
  width: 200px;
  height: 150px;
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
  transition: transform 0.2s;
  border-radius: 9px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.project-item:hover {
  transform: scale(1.05);
}

.project-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.project-title {
  font-size: 16px;
  font-weight: bold;
}

.load-more-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.load-more-btn:hover {
  background-color: #45a049;
}
.create-project-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.create-project-btn:hover {
  background-color: #0056b3;
}

.create-project-form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.create-project-form input,
.create-project-form textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 50%;
  z-index: 2;
}
.create-project-form .submit-btn {
  margin-left: 0;
  width: 50%;
}
.create-project-form::after {
  content: "";
  width: calc(50% + 32px);
  height: calc(100% + 32px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  border-radius: 8px;
  background: white;
  z-index: 1;
}
.create-project-form::after:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}
.submit-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #218838;
}

.loading {
  margin-top: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 16px;
}

.user-info {
  font-size: 16px;
}

.logout-btn {
  padding: 5px 10px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Tab-like Filter Buttons */
.filter-options {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-start;
  gap: 0; /* Remove gap between buttons */
}

.filter-btn {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  border-right: none; /* Remove right border between buttons */
  color: dimgray;
}

.filter-btn:first-child {
  border-radius: 8px 0 0 8px; /* Round the left edge */
}

.filter-btn:last-child {
  border-radius: 0 8px 8px 0; /* Round the right edge */
  border-right: 1px solid #ccc; /* Restore border for the last button */
  margin-left: 0;
}

.filter-btn.active {
  background-color: #4caf50;
  color: white;
  border-color: #4caf50;
}

.filter-btn:not(.active):hover {
  background-color: #e6e6e6;
  border-color: #aaa;
}

.delete {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 4px 8px;
  background-color: #ff4d4f;
  display: none;
}
.project-item:hover .delete {
  display: block;
}
